import { Check, X } from '@phosphor-icons/react'

const CheckMarkIcon = ({
  bgColor,
  checkColor,
  isNegative,
}: { bgColor?: string; checkColor?: string; isNegative?: boolean }) => {
  return (
    <div className={`rounded-full ${bgColor} flex items-center justify-center w-[16px] h-[16px]`}>
      {isNegative ? (
        <X className="text-burgundy w-[10px] h-[10px]" />
      ) : (
        <Check className={`text-${checkColor || 'burgundy'} w-[10px] h-[10px]`} />
      )}
    </div>
  )
}

export default CheckMarkIcon
