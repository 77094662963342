'use client'

import { Heading } from '@/components/ui/Heading'
import { Overline } from '@/components/ui/Overline'
import Paragraph from '@/components/ui/Paragraph'
import Image from 'next/image'
import { twMerge } from 'tailwind-merge'
import CheckMarkIcon from '../../components/ui/CheckMarkIcon'

const FEATURES_DATA = [
  {
    icon: 'meetings',
    title: 'Your Digital Assistant',
    metric: {
      label: 'Meetings Booked',
      value: '124',
      increase: '+10.5%',
    },
    description: 'Taking admin-heavy tasks off your hands',
    capabilities: ['Draft Emails', 'Summarise Calls', 'Book Meetings'],
    avatar: {
      src: '/assets/home/pexels-cristian-rojas-10041269.png',
      alt: 'Digital Assistant Avatar',
    },
  },
  {
    icon: 'messages',
    title: 'Brand Representative',
    metric: {
      label: 'Messages Sent',
      value: '248',
      increase: '+24.5%',
    },
    description: 'Next gen replicas that you can trust',
    capabilities: ['Sales Agent', 'Personalised Chat bot', 'Community Engagement'],
    avatar: {
      src: '/assets/home/christina-wocintechchat-com-SJvDxw0azqw-unsplash.png',
      alt: 'Brand Representative Avatar',
    },
  },
  {
    icon: 'files',
    title: 'Personal Knowledge Sharing',
    metric: {
      label: 'Files Uploaded',
      value: '2k',
      increase: '+140.5%',
    },
    description: 'Ensure the knowledge of your best people is never lost',
    capabilities: ['Knowledge Capturing', 'Knowledge Sharing', 'Access 24/7, Anytime, Anywhere'],
    avatar: {
      src: '/assets/home/pexels-artempodrez-6786908.png',
      alt: 'Knowledge Sharing Avatar',
    },
  },
]

interface FeatureCardProps {
  feature: (typeof FEATURES_DATA)[0]
}

const FeatureCard = ({ feature }: FeatureCardProps) => {
  return (
    <div className="flex flex-col gap-6">
      <div className="flex items-start justify-between relative bg-off-white rounded-md p-4 w-[78%] md:w-[70%] lg:w-[70%]">
        <div className="flex flex-col gap-3 w-full">
          <div className="font-sourcecodepro text-sm text-plum-1">{feature.metric.label}</div>
          <div className="flex gap-2 justify-between w-full mt-2">
            <div className="text-6xl font-light font-butler -mb-3">{feature.metric.value}</div>
            <div className="-h-fit self-end rounded bg-bright-green-light px-2 py-1 text-xs text-burgundy">
              {feature.metric.increase}
            </div>
          </div>
        </div>
        <div className="absolute -right-10 md:-right-10 lg:-right-10 -top-6 bg-gray-200 overflow-hidden rounded-md">
          <Image src={feature.avatar.src} alt={feature.avatar.alt} width={80} height={80} className="scale-110" />
        </div>
      </div>

      <div className="flex flex-col gap-1 pl-1">
        <Heading level="h3" className={twMerge('text-[1.5rem] md:text-[1.7rem] lg:text-[1.8rem] leading-[1.1]')}>
          {feature.title}
        </Heading>
        <Paragraph>{feature.description}</Paragraph>
      </div>

      <ul className="flex flex-col gap-2 pl-0 mt-0 pl-1">
        {feature.capabilities.map((capability) => (
          <li key={capability} className="flex items-center gap-2">
            <CheckMarkIcon bgColor="bg-pastel-blue" />
            {capability}
          </li>
        ))}
      </ul>
    </div>
  )
}

const Features = () => {
  return (
    <section id="features" className="flex flex-col justify-center gap-28 w-full px-5">
      <div className="max-w-7xl w-full mx-auto flex flex-col justify-center items-center gap-5">
        <Overline>Why Sensay?</Overline>
        <Heading level="h2" className="text-center">
          Never miss a beat
        </Heading>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-10 max-w-6xl mx-auto px-4">
        {FEATURES_DATA.map((feature) => (
          <FeatureCard key={feature.title} feature={feature} />
        ))}
      </div>
    </section>
  )
}

export default Features
