import { cn } from '@/lib/utils'
import { type VariantProps, cva } from 'class-variance-authority'
import * as React from 'react'

const paragraphVariants = cva('max-w-[700px] font-normal font-sourcesans3 text-burgundy', {
  variants: {
    size: {
      large: 'text-[1.125rem] lg:text-[1.25rem] leading-[1.5]', // 18px -> 20px
      default: 'text-[1.0625rem] lg:text-[1.125rem] leading-[1.5]', // 17px -> 18px
      small: 'text-base leading-[1.5]', // 16px (constant)
      xsmall: 'text-[0.75rem] leading-[1.5]', // 12px (constant)
    },
  },
  defaultVariants: {
    size: 'default',
  },
})

interface ParagraphProps extends React.HTMLAttributes<HTMLParagraphElement>, VariantProps<typeof paragraphVariants> {}

const Paragraph = React.forwardRef<HTMLParagraphElement, ParagraphProps>(
  ({ className, size, children, ...props }, ref) => {
    return (
      <p ref={ref} {...props} className={cn(paragraphVariants({ size, className }))}>
        {children}
      </p>
    )
  },
)

Paragraph.displayName = 'Paragraph'

export default Paragraph
