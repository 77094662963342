'use client'

import useIsMobile, { TABLET_BREAKPOINT } from '@/hooks/useIsMobile'
import SensayLogo from '@/public/logos/sensay-logo.svg'
import { List, X } from '@phosphor-icons/react/dist/ssr'
import Link from 'next/link'
import { useEffect, useState } from 'react'
import type { HTMLAttributes } from 'react'
import { twMerge } from 'tailwind-merge'
import UserProfileButton from '../UserProfileButton'

interface IMenuBurger {
  title: string
  href: string
}

interface HeaderProps extends HTMLAttributes<HTMLDivElement> {
  isHeroVisible?: boolean
}

const menuBurger: IMenuBurger[] = [
  {
    title: 'About',
    href: '/about',
  },
  {
    title: 'Replicas',
    href: '/replicas',
  },
  {
    title: 'Pricing',
    href: '/pricing/year',
  },
  {
    title: 'Contact',
    href: '/contact',
  },
]

export default function Header({ isHeroVisible = false, ...props }: HeaderProps) {
  const [isOpen, setIsOpen] = useState(false)

  const toggleMenu = () => setIsOpen(!isOpen)
  const closeMenu = () => setIsOpen(false)

  const isMobile = useIsMobile(TABLET_BREAKPOINT)

  useEffect(() => {
    if (!isMobile) {
      closeMenu()
    }
  }, [isMobile])

  return (
    <div className="header-wrapper backdrop-blur-sm sticky top-0 z-[99] w-full" {...props}>
      <nav
        className={twMerge(
          'p-3 text-black transition-all duration-300',
          isOpen ? 'h-[100dvh] backdrop-blur-md bg-background-80 text-black' : '',
          isHeroVisible ? 'bg-background py-6' : 'bg-background-80',
        )}
      >
        <div className="m-auto box-border flex max-w-6xl flex-wrap items-center justify-between">
          <Link
            onClick={closeMenu}
            href="/"
            className="relative z-[99] flex shrink-0 flex-row items-center gap-1 transition duration-300 hover:text-primary"
          >
            <SensayLogo alt="Sensay" className="h-[32px] fill-current" />
            <span className="beta-badge rounded-md bg-bright-plum-7 px-2 py-1 text-xs font-medium text-bright-plum ring-1 ring-inset ring-bright-plum-50">
              βeta
            </span>
          </Link>
          <div className={'order-last hidden md:relative md:order-none md:block'}>
            <div className="flex flex-col items-center justify-start gap-6 md:relative md:top-0 md:flex-row md:pt-0">
              <ul className="m-0 flex flex-col items-center gap-6 p-0 md:flex-row md:justify-center md:py-0">
                {menuBurger.map((item: IMenuBurger) => (
                  <li className="list-none font-sourcecodepro" key={item.title}>
                    <Link
                      onClick={closeMenu}
                      href={item.href}
                      className="mb-6 text-center text-2xl transition duration-300 hover:text-primary md:mb-0 md:text-lg"
                    >
                      {item.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <MobileMenu isOpen={isOpen} closeMenu={closeMenu} />
          <div className="hidden md:contents">
            <UserProfileButton />
          </div>
          <div className="shrink-0 transition-opacity hover:opacity-75 md:hidden">
            <button
              onClick={toggleMenu}
              className="flex focus:outline-none"
              id="header-disclosure-button"
              aria-label="Toggle menu"
            >
              <List aria-hidden="true" size={34} className={`${isOpen ? 'hidden' : 'block'}`} />
              <X size={34} className={`${isOpen ? 'block' : 'hidden'}`} aria-hidden="true" />
            </button>
          </div>
        </div>
      </nav>
    </div>
  )
}

function MobileMenu({ isOpen, closeMenu }: { isOpen: boolean; closeMenu: () => void }) {
  if (!isOpen) {
    return null
  }
  return (
    <div className={'order-last absolute block top-36 left-0 w-full flex items-center justify-center'}>
      <div className="flex flex-col items-center justify-start gap-6 md:relative md:top-0 md:flex-row md:pt-0">
        <ul className="m-0 flex flex-col items-center gap-6 p-0 md:flex-row md:justify-center md:py-0">
          {menuBurger.map((item: IMenuBurger) => (
            <li className="list-none font-sourcecodepro" key={item.title}>
              <Link
                onClick={closeMenu}
                href={item.href}
                className="mb-6 text-center text-2xl transition duration-300 hover:text-primary md:mb-0 md:text-lg"
              >
                {item.title}
              </Link>
            </li>
          ))}
          <UserProfileButton />
        </ul>
      </div>
    </div>
  )
}
