import { animate, motion, useMotionTemplate, useMotionValue, useTransform } from 'framer-motion'
import { useEffect } from 'react'
import { twMerge } from 'tailwind-merge'

const CompletedTasks = () => {
  const count = useMotionValue(200)
  const rounded = useTransform(count, Math.round)
  const display = useMotionTemplate`68,${rounded}`

  useEffect(() => {
    animate(count, 405, {
      duration: 3,
      ease: 'easeOut',
      delay: 0.4,
    })
  }, [count])

  return (
    <motion.div
      className="p-3 px-4 rounded-md h-fit w-fit bg-bright-green flex items-center justify-between gap-8 md:gap-12 lg:gap-16 shadow-box"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{
        duration: 0.7,
        ease: 'easeOut',
        opacity: { duration: 1.1 },
      }}
    >
      <div
        className={twMerge(
          'font-sourcecodepro uppercase text-burgundy flex items-center gap-3 font-light',
          'text-sm md:text-base lg:text-lg',
        )}
      >
        <motion.div
          className="h-2 w-2 bg-burgundy origin-center"
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            duration: 0.4,
            ease: 'easeOut',
            delay: 0.3,
          }}
        />
        <motion.span
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
            duration: 0.6,
            ease: 'easeOut',
            delay: 0.5,
          }}
          className="whitespace-nowrap"
        >
          Completed Tasks
        </motion.span>
      </div>
      <div
        className={twMerge(
          'font-butler font-normal text-[#627F11] -mb-0.5 w-[56px]',
          'text-base md:text-lg lg:text-lg',
        )}
      >
        <motion.span>{display}</motion.span>
      </div>
    </motion.div>
  )
}

export default CompletedTasks
