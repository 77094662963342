'use client'

import { Button } from '@/components/ui/Button'
import { Heading } from '@/components/ui/Heading'
import { Overline } from '@/components/ui/Overline'
import Paragraph from '@/components/ui/Paragraph'
import Link from 'next/link'
import CheckMarkIcon from '../../components/ui/CheckMarkIcon'
import PlanTag from '../pricing/[[...slugs]]/PlanTag'
import { PROFESSIONAL_SENSAY_PLAN, STARTER_SENSAY_PLAN, stripePlans } from '../pricing/[[...slugs]]/stripe-plans'

export type Feature = {
  text: string
  id: string
}

export type Plan = {
  id: string
  tag: {
    title: string
    className: string
  }
  title: string
  description: string
  features: Feature[]
  colorAccent: string
}

export const plans: Plan[] = [
  {
    id: 'starter',
    tag: {
      title: 'STARTER',
      className: 'bg-pastel-blue',
    },
    title: 'Starter Plan',
    description: 'Best for individuals.',
    colorAccent: 'text-blue-500',
    features: stripePlans[STARTER_SENSAY_PLAN].keyFeatures.map((text, index) => ({
      id: `starter-${index}`,
      text,
    })),
  },
  {
    id: 'professional',
    tag: {
      title: 'PROFESSIONAL',
      className: 'bg-pastel-orange',
    },
    title: 'Professional Plan',
    description: 'Best for professionals, teams and startups.',
    colorAccent: 'text-orange-500',
    features: stripePlans[PROFESSIONAL_SENSAY_PLAN].keyFeatures.map((text, index) => ({
      id: `professional-${index}`,
      text,
    })),
  },
]

const SensayPlanComparison = () => {
  return (
    <section id="membership-comparison" className="w-full px-5 -mt-20">
      <div className="max-w-7xl mx-auto w-full grid grid-cols-1 md:grid-cols-1 lg:grid-cols-[1fr,auto,auto] gap-16 justify-between items-start md:items-center md:justify-items-center">
        {/* Left Column - ROI Section */}
        <div className="space-y-6 max-w-xl">
          <Overline>The real value is your time</Overline>
          <Heading level="h2">Maximize your potential</Heading>

          <Paragraph size="large">
            Sensay's AI-powered digital replicas help you streamline operations, amplify your team's expertise, and keep
            your business running around the clock. Make faster decisions, boost productivity, and cut costs - all
            without the extra effort. Unlock new levels of efficiency. Bring Sensay into your business and see it
            flourish — while keeping your to-do list stress-free.
          </Paragraph>

          <div className="flex gap-4">
            <Button asChild>
              <Link href="/me">Try Now</Link>
            </Button>

            <Button variant="secondary" asChild>
              <Link href="/pricing/year">Discover Your Plan</Link>
            </Button>
          </div>
        </div>

        {/* Membership Plans - will stack vertically on md and smaller screens */}
        <div className="md:col-span-1 lg:col-span-2 grid md:grid-cols-1 lg:grid-cols-1 gap-10">
          {plans.map((plan) => (
            <div key={plan.id} className="flex flex-col md:flex-row gap-4">
              <div className="flex flex-col gap-3 w-[260px] max-w-[260px]">
                <PlanTag title={plan.tag.title} className={plan.tag.className} />
                <Heading level="h3" className="mt-2">
                  {plan.title}
                </Heading>
                <p className="text-muted-foreground">{plan.description}</p>
              </div>

              <div className="space-y-3">
                <Heading level="h4">Key Features</Heading>
                <ul className="space-y-2 p-0">
                  {plan.features.map((feature) => (
                    <li key={feature.id} className="flex items-center gap-2">
                      <CheckMarkIcon bgColor={plan.tag.className} checkColor={plan.colorAccent} />
                      <span>{feature.text}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default SensayPlanComparison
