'use client'

import Alert from '@/components/Alert'
import Footer from '@/components/layout/Footer'
import Header from '@/components/layout/Header'
import { Button } from '@/components/ui/Button'
import { captureException } from '@sentry/nextjs'
import type Error from 'next/error'
import { useEffect, useState } from 'react'

export default function ErrorBox({
  error,
  reset,
  errorOnly = false,
}: {
  error: Error & { message?: string; digest?: string }
  reset: () => void
  errorOnly: boolean
}) {
  const [sentryErrorId, setSentryErrorId] = useState<string | undefined>(undefined)

  useEffect(() => {
    setSentryErrorId(captureException(error))
  }, [error])

  return (
    <>
      {errorOnly || <Header />}
      <div className="mx-auto flex max-w-3xl flex-col justify-center gap-6 px-6 py-24">
        <Alert
          variant="destructive"
          title="Uh oh, something went wrong!"
          buttons={
            <Button size={'small'} onClick={() => reset()}>
              Try again
            </Button>
          }
          description={
            <div className="flex flex-col">
              <p>Try again later, or send us your feedback if this problem continues to occur.</p>
              {sentryErrorId && <p>Id: {sentryErrorId}</p>}
              {error.digest && <p>Details: {error.digest}</p>}
              {error.message && <p>{error.message}</p>}
            </div>
          }
        />
      </div>

      {errorOnly || <Footer />}
    </>
  )
}
