export const Overline = ({ children, className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <h4
      className={`text-bright-plum uppercase font-light font-sourcecodepro text-base lg:text-[1.125rem] ${className}`}
      {...props}
    >
      {children}
    </h4>
  )
}
