import { File as NodeFile } from '@web-std/file'
import BigNumber from 'bignumber.js'
import { type ClassValue, clsx } from 'clsx'
import SparkMD5 from 'spark-md5'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const capitalizeFirstCharacter = (word: string) => {
  if (!word) return word
  return word.charAt(0).toUpperCase() + word.slice(1)
}

export const calculateFileHash = async (_file: File): Promise<string> => {
  let file = _file
  // Convert Buffer to File if we're in Node environment
  if (Buffer.isBuffer(file)) {
    file = new NodeFile([file], 'upload', { type: 'application/octet-stream' }) as File
  }

  // Check if we're in Node environment
  if (typeof window === 'undefined') {
    // Node.js environment
    const buffer = await file.arrayBuffer()
    const uint8Array = new Uint8Array(buffer)
    let binaryStr = ''
    const chunkSize = 1024

    for (let i = 0; i < uint8Array.length; i += chunkSize) {
      binaryStr += String.fromCharCode.apply(null, uint8Array.slice(i, i + chunkSize) as unknown as number[])
    }

    return SparkMD5.hashBinary(binaryStr)
  }

  // Browser environment
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (event) => {
      const arrayBuffer = event?.target?.result as ArrayBuffer
      const uint8Array = new Uint8Array(arrayBuffer)
      let binaryStr = ''
      const chunkSize = 1024

      for (let i = 0; i < uint8Array.length; i += chunkSize) {
        binaryStr += String.fromCharCode.apply(null, uint8Array.slice(i, i + chunkSize) as unknown as number[])
      }

      const hash = SparkMD5.hashBinary(binaryStr)
      resolve(hash)
    }
    reader.onerror = reject
    reader.readAsArrayBuffer(file)
  })
}

export const defaultEmbedding = 'multilingual-e5-large'

export function sizeToHuman(_size: number | string) {
  const units = ['bytes', 'KB', 'MB', 'GB', 'TB']
  let unitIndex = 0
  let size = Number(_size)
  while (size > 1024 && unitIndex < units.length) {
    size /= 1024
    unitIndex++
  }
  return `${BigNumber(size).toFormat()} ${units[unitIndex]}`
}
