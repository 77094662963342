'use client'
import { useEffect, useLayoutEffect, useState } from 'react'

export const MOBILE_BREAKPOINT = 425
export const TABLET_BREAKPOINT = 768

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: typeof window !== 'undefined' ? window?.innerWidth : null,
    height: typeof window !== 'undefined' ? window?.innerHeight : null,
  })

  useEffect(() => {
    const handleResize = () => {
      if (typeof window !== 'undefined') {
        setWindowSize({
          width: window?.innerWidth,
          height: window?.innerHeight,
        })
      }
    }

    window?.addEventListener('resize', handleResize)

    handleResize()

    return () => window?.removeEventListener('resize', handleResize)
  }, [])

  return windowSize
}

const useIsMobile = (breakpoint = MOBILE_BREAKPOINT) => {
  const { width } = useWindowSize()
  const [isMobile, setMobile] = useState<boolean | null>(width ? width <= breakpoint : null) // starts as null to indicate no initial assumption

  useLayoutEffect(() => {
    if (width !== null) {
      setMobile(width <= breakpoint)
    }
  }, [width, breakpoint])

  return isMobile
}

export function RenderBasedOnScreenWidth({
  renderSmall,
  renderLarge,
  breakpoint = MOBILE_BREAKPOINT,
}: { renderSmall: () => React.ReactNode; renderLarge: () => React.ReactNode; breakpoint?: number }) {
  const isMobile = useIsMobile(breakpoint)

  return isMobile ? renderSmall() : renderLarge()
}

export default useIsMobile
