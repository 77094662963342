import { motion } from 'framer-motion'
import CompletedTasks from './CompletedTasks'
import EfficiencyChart from './EfficiencyChart'
import HoursSaved from './HoursSaved'

const HeroAnimations = () => {
  return (
    <motion.div
      className="absolute left-1/2 -translate-x-1/2 h-full w-full z-[50]
                 grid grid-cols-1 md:grid-cols-[60%_40%] lg:grid-cols-[60%_40%] px-4
                 overflow-visible"
      initial="hidden"
      animate="visible"
      variants={{
        visible: {
          transition: {
            staggerChildren: 1.4,
          },
        },
      }}
    >
      {/* Left Column */}
      <div className="flex flex-col justify-between">
        <motion.div
          className="mt-10 md:mt-20 lg:mt-20 -ml-2 md:ml-0 lg:ml-0"
          variants={{
            hidden: { opacity: 0, y: 20 },
            visible: {
              opacity: 1,
              y: 0,
              transition: { duration: 0.8, ease: 'easeOut' },
            },
          }}
        >
          <CompletedTasks />
        </motion.div>

        <motion.div
          className="mb-0 sm:mb-32 md:mb-32 lg:mb-32 self-end md:self-start lg:self-start"
          variants={{
            hidden: { opacity: 0, y: 20 },
            visible: {
              opacity: 1,
              y: 0,
              transition: { duration: 1.1, ease: 'easeOut' },
            },
          }}
        >
          <HoursSaved />
        </motion.div>
      </div>

      {/* Right Column */}
      <div className="md:flex lg:flex hidden items-end md:items-center justify-end">
        <motion.div
          className="-mt-28 md:mt-[20%] -mr-4"
          variants={{
            hidden: { opacity: 0, y: 20 },
            visible: {
              opacity: 1,
              y: 0,
              transition: { duration: 1.1, ease: 'easeOut' },
            },
          }}
        >
          <EfficiencyChart />
        </motion.div>
      </div>
    </motion.div>
  )
}

export default HeroAnimations
