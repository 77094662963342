import { CaretDown } from '@phosphor-icons/react'
import { animate, motion, useMotionValue } from 'framer-motion'
import dynamic from 'next/dynamic'
import {} from 'react'
import { useEffect } from 'react'
import {} from 'recharts'
import type { DotProps } from 'recharts'
import { twMerge } from 'tailwind-merge'

const finalData = Array.from({ length: 26 }, (_, i) => {
  const day = i + 0
  let efficiency: number

  if (day < 12) {
    efficiency = 200 + day * 8
  } else {
    efficiency = 296 + (day - 12) * 50
  }

  return {
    date: day.toString(),
    efficiency: efficiency,
  }
}).filter((point) => point.efficiency <= 1000)

const Chart = dynamic(
  () =>
    import('recharts').then((recharts) => {
      const { LineChart, CartesianGrid, XAxis, YAxis, Line } = recharts
      return function Chart() {
        return (
          <LineChart
            width={180}
            height={160}
            data={finalData}
            margin={{ top: 20, right: 5, left: -55, bottom: -10 }}
            className="scale-[0.75] md:scale-90 lg:scale-100 origin-left"
          >
            <CartesianGrid horizontal={true} vertical={false} stroke="rgba(0, 0, 0, 0.1)" />

            <XAxis dataKey="date" stroke="#666" tick={false} axisLine={false} tickLine={false} />
            <YAxis
              stroke="rgb(153, 145, 148)"
              tick={{
                fill: '#627F11',
                fontSize: 10,
                dx: 11,
                dy: -9,
                textAnchor: 'start',
              }}
              axisLine={false}
              tickLine={false}
              domain={[200, 1000]}
              ticks={[200, 400, 600, 800, 1000]}
              interval={0}
            />

            <Line
              type="basis"
              dataKey="efficiency"
              stroke="#a3426c"
              strokeWidth={2}
              dot={(props: DotProps & { index: number }): React.ReactElement<SVGElement> => {
                const isFirstOrLast = props.index === 0 || props.index === finalData.length - 1
                return isFirstOrLast ? <circle cx={props.cx} cy={props.cy} r={4} fill="#a3426c" /> : <circle r={0} />
              }}
              activeDot={{ r: 6 }}
              animationDuration={1000}
              animationBegin={2900}
            />
          </LineChart>
        )
      }
    }),
  { ssr: false },
)

const EfficiencyChart = () => {
  const costCount = useMotionValue(3800)
  const efficiencyCount = useMotionValue(300)

  useEffect(() => {
    const controls1 = animate(costCount, 4000, {
      duration: 3,
      ease: 'easeOut',
      delay: 2.4,
    })
    const controls2 = animate(efficiencyCount, 360, {
      duration: 3,
      ease: 'easeOut',
      delay: 2.4,
    })

    return () => {
      controls1.stop()
      controls2.stop()
    }
  }, [costCount, efficiencyCount])

  return (
    <motion.div
      id="efficiencyChart"
      className="p-3 px-4 relative rounded-md w-fit bg-bright-green flex flex-col gap-2 md:gap-3 lg:gap-3 shadow-box w-[180px] md:w-[200px] lg:w-[220px] origin-bottom"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{
        duration: 0.7,
        ease: 'easeOut',
        opacity: { duration: 1.1 },
      }}
    >
      <div
        className={twMerge(
          'font-sourcecodepro uppercase text-burgundy flex items-center gap-3 font-light',
          'text-sm md:text-base lg:text-lg',
        )}
      >
        <motion.div
          className="h-2 w-2 bg-burgundy origin-center"
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            duration: 0.4,
            ease: 'easeOut',
            delay: 2.8,
          }}
        />
        Efficiency
      </div>

      <div className="flex items-center gap-1 text-[#627F11] flex items-center text-sm">
        January <CaretDown className="w-4 h-4" />
      </div>

      <motion.div
        className="z-10 absolute top-28 left-20 bg-bright-plum text-off-white p-1.5 px-2.5 text-xs rounded-full"
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 0.5,
          ease: 'easeOut',
          delay: 4.5,
        }}
      >
        +200%
      </motion.div>

      <motion.div
        className="origin-bottom"
        initial={{ scaleY: 0 }}
        animate={{ scaleY: 1 }}
        transition={{
          duration: 1.2,
          ease: 'easeOut',
          delay: 2.5,
        }}
      >
        <Chart />
      </motion.div>
    </motion.div>
  )
}

export default EfficiencyChart
