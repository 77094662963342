'use client'

import { Heading } from '@/components/ui/Heading'
import { Overline } from '@/components/ui/Overline'
import Paragraph from '@/components/ui/Paragraph'
import { AnimatePresence, motion } from 'framer-motion'
import Image from 'next/image'
import { useState } from 'react'
import { Pagination } from './components/Pagination'

// Quote max 290 char

const testimonials = [
  {
    name: 'Claire Rennie',
    company: 'KOLZ',
    position: 'Project Lead',
    quote:
      "“Without Sensay, KOLZ wouldn't exist. Their tech powers our influencer digital replicas, automating engagement and unlocking new opportunities. Sensay's integration lets our community connect with influencers like never before, making what once seemed out of reach, possible..”",
    image: '/assets/home/claire.jpg',
  },
  {
    name: 'Rob Spitz',
    company: 'KOLZ',
    position: 'Social Media Lead',
    quote:
      "“I'm passionate about Sensay's technology, having first created my own digital replica back in January 2024. When we needed lifelike digital replicas for KOLZ, we went straight to Sensay. Since then, they've supported us through a successful TGE and will help us through our upcoming plans.”",
    image: '/assets/home/rob.jpg',
  },
]

interface TestimonialCardProps {
  testimonial: {
    name: string
    company: string
    position: string
    quote: string
    image: string
  }
}

const TestimonialCard = ({ testimonial }: TestimonialCardProps) => {
  return (
    <div className="flex gap-3 md:gap-10 lg:gap-10 p-0 md:p-10 lg:p-10">
      <Image
        src={testimonial.image}
        alt={testimonial.name}
        width={300}
        height={400}
        className="w-[80px] h-[100px] md:w-[300px] md:h-[400px] object-cover object-center rounded-md flex-shrink-0"
      />
      <div className="flex flex-col gap-2 md:gap-4 justify-between">
        <Paragraph className=" text-xl sm:text-2xl md:text-2xl lg:text-3xl leading-tight md:leading-12 text-burgundy font-butler">
          {testimonial.quote}
        </Paragraph>
        <div className="flex flex-col">
          <Heading level="h5" className="text-bright-plum text-sm md:text-base">
            {testimonial.name}
          </Heading>
          <div className="text-bright-plum text-sm md:text-base">{testimonial.position}</div>
          <div className="text-bright-plum text-sm md:text-base">{testimonial.company}</div>
        </div>
      </div>
    </div>
  )
}

const Testimonials = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const currentTestimonial = testimonials[currentPage - 1]

  return (
    <section
      id="testimonials"
      className="flex flex-col justify-center mx-auto gap-10 -mt-36 px-5 sm:max-w-xl md:max-w-7xl lg:max-w-7xl"
    >
      <div className="flex flex-col justify-center items-center gap-5">
        <Overline>Testimonials</Overline>
        <Heading level="h2" className="text-center">
          Don't just take our
          <br /> word for it
        </Heading>
      </div>
      <div className="relative flex flex-col gap-8">
        <div className="min-h-[280px] md:min-h-[480px]">
          <AnimatePresence mode="wait">
            <motion.div
              key={currentPage}
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -20 }}
              transition={{ duration: 0.3 }}
            >
              <TestimonialCard testimonial={currentTestimonial} />
            </motion.div>
          </AnimatePresence>
        </div>
        <div className="flex justify-center w-full">
          <Pagination currentPage={currentPage} totalPages={testimonials.length} onPageChange={setCurrentPage} />
        </div>
      </div>
    </section>
  )
}

export default Testimonials
