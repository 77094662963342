'use client'
import { Heading } from '@/components/ui/Heading'
import Paragraph from '@/components/ui/Paragraph'
import { animate, motion, useMotionValue, useTransform } from 'framer-motion'
import Image from 'next/image'
import { useEffect } from 'react'

const metrics = [
  {
    value: 25,
    unit: '%',
    description: 'Reduction in overtime',
    image: '/assets/home/overtime-yearly-graphic.png',
  },
  {
    value: 70,
    unit: '%',
    description: 'Employees feel less stressed',
    image: '/assets/home/wellbeing-index-graphic.png',
  },
  {
    value: 8,
    unit: ' hours',
    description: 'More free time per week',
    image: '/assets/home/message-center-graphic.png',
  },
  {
    value: 50,
    unit: '%',
    description: 'Reduction in work admin',
    image: '/assets/home/cost-reduction-graphic.png',
  },
]

const KeyMetricsCard = ({ metric }: { metric: (typeof metrics)[0] }) => {
  const count = useMotionValue(0)
  const cardTitle = useTransform(count, (value) => `${Math.round(value)}${metric.unit}`)

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          animate(count, metric.value, {
            duration: 3,
            ease: 'easeOut',
          })
        }
      },
      { threshold: 0.1 },
    )

    const element = document.getElementById(`metric-${metric.value}${metric.unit}`)
    if (element) {
      observer.observe(element)
    }

    return () => observer.disconnect()
  }, [count, metric.value, metric.unit])

  return (
    <div id={`metric-${metric.value}${metric.unit}`} className="bg-off-white rounded-md flex flex-col h-[340px] w-full">
      <div className="relative h-[55%]">
        <Image src={metric.image} alt="" fill className="object-cover object-top rounded-t-[8px] bg-gray-200" />
      </div>
      <div className="p-4 flex flex-col gap-2 justify-center">
        <Heading level="h2">
          <motion.span>{cardTitle}</motion.span>
        </Heading>
        <Paragraph size="large">{metric.description}</Paragraph>
      </div>
    </div>
  )
}

const KeyMetrics = () => {
  return (
    <div id="key-metrics" className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 max-w-7xl mx-auto px-4">
      {metrics.map((metric) => (
        <KeyMetricsCard key={`${metric.value}${metric.unit}`} metric={metric} />
      ))}
    </div>
  )
}

export default KeyMetrics
