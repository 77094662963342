import { Slot } from '@radix-ui/react-slot'
import { type VariantProps, cva } from 'class-variance-authority'
import * as React from 'react'

import { cn } from '@/lib/utils'

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-md text-[16px] font-sourcecodepro font-light leading-[20.11px] text-center transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:bg-slate-200 disabled:text-slate-400 disabled:border-slate-200 [&_svg]:pointer-events-none [&_svg]:shrink-0 w-fit',
  {
    variants: {
      variant: {
        default:
          'bg-[var(--primary-bg)] text-[var(--primary-text)] shadow hover:bg-[var(--primary-hover-bg)] hover:text-[var(--primary-hover-text)] active:bg-[var(--primary-hover-bg)] active:text-[var(--primary-hover-text)]',
        secondary:
          'bg-[var(--secondary-bg)] text-[var(--secondary-text)] border border-[var(--secondary-border)] shadow-sm hover:border-[var(--secondary-hover-border)] hover:text-[var(--secondary-hover-text)] hover:bg-[var(--secondary-hover-bg)] active:border-[var(--secondary-hover-border)] active:text-[var(--secondary-hover-text)] active:bg-[var(--secondary-active-bg)]',
        'secondary-active':
          'active:border-[var(--secondary-hover-border)] active:text-[var(--secondary-hover-text)] active:bg-[var(--secondary-active-bg)] disabled:border-[var(--secondary-hover-border)] disabled:text-[var(--secondary-hover-text)] disabled:bg-[var(--secondary-active-bg)]',
        'secondary-white':
          'bg-[var(--color-burgundy)] text-[var(--color-off-white)] border border-[var(--color-off-white)] shadow-sm hover:border-[var(--secondary-hover-border)] hover:text-[var(--secondary-hover-text)] hover:bg-[var(--secondary-hover-bg)] active:border-[var(--secondary-hover-border)] active:text-[var(--secondary-hover-text)] active:bg-[var(--secondary-active-bg)]',
        ghost: 'text-[var(--secondary-text)] hover:bg-[var(--secondary-bg)] active:bg-[var(--secondary-hover-bg)]',
        red: 'bg-[var(--color-red)] text-[var(--primary-text)] hover:bg-[var(--color-red-light)] active:bg-[var(--color-red-intense)]',
        white:
          'bg-white text-[var(--secondary-text)] border border-white hover:bg-[var(--color-bright-plum-25)] active:bg-[var(--color-bright-plum-50)]',
        link: 'text-[var(--secondary-text)] underline !p-0',
      },
      size: {
        default: 'h-[52px] gap-[8px] px-[16px] rounded-[4px] text-[16px] [&_svg]:size-6',
        medium: 'h-[44px] gap-[8px] px-[16px] rounded-[4px] text-[15px] [&_svg]:size-5',
        small: 'h-[33px] gap-[4px] px-[12px] rounded-[4px] text-[15px] [&_svg]:size-4',
        icon: 'h-9 w-9',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
  theme?: 'light' | 'dark'
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant = 'default', size = 'default', theme = 'light', asChild = false, disabled, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button'
    return (
      <Comp
        className={cn(
          buttonVariants({ variant, size, className }),
          theme,
          asChild && disabled && 'pointer-events-none bg-slate-200 text-slate-400 border-slate-200',
        )}
        ref={ref}
        disabled={disabled}
        {...props}
      />
    )
  },
)
Button.displayName = 'Button'

export { Button, buttonVariants }
