interface PaginationProps {
  currentPage: number
  totalPages: number
  className?: string
}

export function Pagination({
  currentPage,
  totalPages,
  className = '',
  onPageChange,
}: PaginationProps & { onPageChange: (page: number) => void }) {
  return (
    <div className={`flex items-center gap-4 py-2 ${className}`}>
      {/* Left Arrow */}
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        aria-label="Previous page"
        className={`flex items-center justify-center text-bright-plum ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer hover:opacity-80'}`}
      >
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className="text-bright-plum">
          <path
            d="M24 12H3M3 12L6 15M3 12L6 9"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>

      {/* Page Numbers */}
      <div className="flex items-center gap-2 text-bright-plum text-lg">
        <span>{currentPage}</span>
        <span>/</span>
        <span>{totalPages}</span>
      </div>

      {/* Right Arrow */}
      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        aria-label="Next page"
        className={`flex items-center justify-center text-bright-plum ${currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer hover:opacity-80'}`}
      >
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className="text-bright-plum">
          <path
            d="M0 12H21M21 12L18 9M21 12L18 15"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </div>
  )
}
