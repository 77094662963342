'use client'
import { GTM_HEADER_MY_SENSAY_ID } from '@/lib/gtm'
import { signIn, signOut, useSession } from 'next-auth/react'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { useDisconnect } from 'wagmi'
import { Button } from './ui/Button'
import Spinner from './ui/Spinner'

function LoadingSessionButton() {
  return (
    <Button
      variant="secondary"
      size="small"
      onClick={() => {
        close()
        signIn()
      }}
      disabled
    >
      <Spinner text="Loading" />
    </Button>
  )
}

function SignOutButton() {
  const { disconnect } = useDisconnect()
  return (
    <Button
      variant="secondary"
      size="small"
      onClick={() => {
        disconnect()
        signOut()
      }}
    >
      Sign Out
    </Button>
  )
}

function MySensayButton({
  userId,
  userPlan,
  userEmail,
}: { transparent?: boolean; userId: string | undefined; userPlan: string | undefined; userEmail: string | undefined }) {
  return (
    <Button
      asChild
      variant="secondary"
      size="small"
      data-user-uuid={userId}
      data-user-plan={userPlan}
      data-user-email={userEmail}
      id={GTM_HEADER_MY_SENSAY_ID}
    >
      <Link href="/me">My Sensay</Link>
    </Button>
  )
}

function SignInButton() {
  return (
    <Button variant="secondary" size="small" asChild>
      <Link href="/sign-in">Sign In</Link>
    </Button>
  )
}

function UserProfileButton() {
  const session = useSession()
  const pathname = usePathname()

  const { status } = session
  const userId = session.data?.userId ?? undefined
  const userEmail = session.data?.user.email ?? undefined
  const userPlan = session.data?.customer.plan ?? undefined
  const isLoadingSession = status === 'loading'
  const isLoggedIn = status === 'authenticated'

  const hideSignInPaths = ['/sign-in']
  const signoutButtonPaths = ['/me']

  const hideSignIn = hideSignInPaths.includes(pathname)
  const showSignoutButton = isLoggedIn && signoutButtonPaths.includes(pathname)

  if (isLoadingSession && !hideSignIn) {
    return <LoadingSessionButton />
  }

  if (isLoggedIn) {
    if (showSignoutButton) {
      return <SignOutButton />
    }

    return <MySensayButton userId={userId} userPlan={userPlan} userEmail={userEmail} />
  }

  if (!hideSignIn) {
    return <SignInButton />
  }

  return null
}

export default UserProfileButton
