'use client'
import { Heading } from '@/components/ui/Heading'
import { Overline } from '@/components/ui/Overline'
import Paragraph from '@/components/ui/Paragraph'
import Image from 'next/image'
import { useState } from 'react'
import { Pagination } from './components/Pagination'

const caseStudies = [
  {
    title: "Personalization at scale: How Sensay's AI breathed authenticity into global retail engagement",
    category: 'Retail',
    description:
      "Sensay's AI replaced bland templates with region-specific personalization, boosting brand loyalty, repeat purchases, and swift, empathetic engagement.",
    image: '/assets/home/retail-optimized.webp',
  },
  {
    title:
      "Revving up the auto industry: How Sensay's AI sparked a road to higher conversions and streamlined lead qualifying",
    category: 'Automotive',
    description:
      "Sensay's AI analyzed calls, flagged top leads, and guided reps with tailored coaching—accelerating deals, boosting transfers, and fueling revenue gains.",
    image: '/assets/home/automotive-optimized.webp',
  },
  {
    title:
      "Dialing in on efficiency: Sensay's AI knowledge management ushers telecom operations into a new era of speedy resolutions",
    category: 'Telecom',
    description:
      "Disjointed data slowed a telecom giant until Sensay's AI centralized info, cut wait times, and empowered agents—driving first-call resolution and heightening customer satisfaction.",
    image: '/assets/home/telecom-optimized.webp',
  },
  {
    title: "Healing through innovation: How Sensay's AI transformed healthcare training and elevated patient care",
    category: 'Healthcare',
    description:
      "Overwhelmed new hires and inconsistent care plagued a top healthcare provider until Sensay's AI modules accelerated onboarding, boosted compliance, and elevated patient outcomes.",
    image: '/assets/home/healthcare-optimized.webp',
  },
]

type CaseStudy = {
  title: string
  category: string
  description: string
  image: string
}

function CaseStudyCard({ title, category, description, image }: CaseStudy) {
  return (
    <div className="flex flex-col md:flex-row items-center gap-14 p-5 w-full">
      <Image
        src={image}
        alt={title}
        width={500}
        height={500}
        className="bg-gray-200 w-[330px] h-[400px] object-cover rounded-md"
      />

      <div className="flex flex-col gap-6 max-w-xl">
        <div className="bg-bright-green w-fit px-3 py-1 rounded-sm">
          <div className="text-burgundy uppercase font-normal font-sourcecodepro">{category}</div>
        </div>
        <div className="flex flex-col gap-2">
          <Heading level="h3">{title}</Heading>
          <Paragraph>{description}</Paragraph>
        </div>

        {/* <Button variant="secondary" asChild>
          <Link href="/case-studies">Read more</Link>
        </Button> */}
      </div>
    </div>
  )
}

const CaseStudies = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [touchStart, setTouchStart] = useState<number | null>(null)
  const [touchEnd, setTouchEnd] = useState<number | null>(null)

  // Minimum swipe distance (in px)
  const minSwipeDistance = 50

  const onTouchStart = (e: React.TouchEvent) => {
    setTouchEnd(null)
    setTouchStart(e.targetTouches[0].clientX)
  }

  const onTouchMove = (e: React.TouchEvent) => {
    setTouchEnd(e.targetTouches[0].clientX)
  }

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return

    const distance = touchStart - touchEnd
    const isLeftSwipe = distance > minSwipeDistance
    const isRightSwipe = distance < -minSwipeDistance

    if (isLeftSwipe && currentPage < caseStudies.length) {
      setCurrentPage((prev) => prev + 1)
    }
    if (isRightSwipe && currentPage > 1) {
      setCurrentPage((prev) => prev - 1)
    }
  }

  return (
    <section id="case-studies" className="flex flex-col justify-center gap-20 px-5">
      <div className="flex flex-col justify-center items-center gap-5">
        <Overline>Case Studies</Overline>
        <Heading level="h2" className="text-center max-w-2xl">
          Driving transformation across industries
        </Heading>
      </div>

      <div className="flex flex-col justify-center items-center gap-2">
        <div className="relative w-full overflow-hidden">
          <div className="max-w-7xl mx-auto">
            <div
              className="flex transition-transform duration-500 ease-in-out touch-pan-y"
              style={{
                transform: `translateX(-${Math.min(currentPage - 1, caseStudies.length - 1) * 90}%)`,
              }}
              onTouchStart={onTouchStart}
              onTouchMove={onTouchMove}
              onTouchEnd={onTouchEnd}
            >
              {caseStudies.map((caseStudy, index) => (
                <div
                  key={index}
                  className="flex-shrink-0 w-[90%]"
                  style={{
                    opacity: currentPage - 1 === index ? 1 : 0.9,
                  }}
                >
                  <CaseStudyCard
                    title={caseStudy.title}
                    category={caseStudy.category}
                    description={caseStudy.description}
                    image={caseStudy.image}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="flex justify-center w-full">
          <Pagination currentPage={currentPage} totalPages={caseStudies.length} onPageChange={setCurrentPage} />
        </div>
      </div>
    </section>
  )
}

export default CaseStudies
