import { animate, motion, useMotionValue, useTransform } from 'framer-motion'
import { useEffect } from 'react'
import { twMerge } from 'tailwind-merge'

const HoursSaved = () => {
  const count = useMotionValue(789)
  const formatted = useTransform(count, Math.round)

  useEffect(() => {
    animate(count, 899, {
      duration: 3,
      ease: 'easeOut',
      delay: 1.7,
    })
  }, [count])

  return (
    <motion.div
      className="p-3 px-4 rounded-md h-fit w-[160px] md:w-[180px] lg:w-[208px] bg-bright-plum flex flex-col gap-3 shadow-box"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{
        duration: 0.7,
        ease: 'easeOut',
        opacity: { duration: 1.1 },
      }}
    >
      <div
        className={twMerge(
          'font-sourcecodepro uppercase text-off-white flex items-center gap-3 font-light whitespace-nowrap',
          'text-sm md:text-base lg:text-lg',
        )}
      >
        <motion.div
          className="h-2 w-2 bg-off-white origin-center"
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            duration: 0.4,
            ease: 'easeOut',
            delay: 1.7,
          }}
        />
        Hours Saved
      </div>

      <div
        className={
          'font-semibold font-butler text-off-white mt-1 -mb-2 opacity-80 text-[5rem] md:text-[5.5rem] lg:text-[6.5rem] leading-none'
        }
      >
        <motion.span>{formatted}</motion.span>
      </div>
      <div className="flex items-center gap-1 text-off-white flex items-center text-sm">Last Quarter</div>
    </motion.div>
  )
}

export default HoursSaved
