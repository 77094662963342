import { Button } from '@/components/ui/Button'
import { cn } from '@/lib/utils'
import Link from 'next/link'

const PlanTag = ({ className, title }: { className: string; title: string }) => {
  return (
    <Button
      size="small"
      className={cn(
        'flex items-center w-fit text-[14px] py-[2px] px-[12px] gap-[8px] rounded-sm font-sourcecodepro text-black hover:text-inherit',
        className,
      )}
      asChild
    >
      <Link href="/pricing">
        <div className="bg-burgundy h-[4px] w-[4px]" />
        {title}
      </Link>
    </Button>
  )
}

export default PlanTag
