import { type VariantProps, cva } from 'class-variance-authority'
import * as React from 'react'

import { cn } from '@/lib/utils'

const alertVariants = cva(
  'relative w-full rounded-[8px] p-4 [&>svg~*]:pl-7 [&>svg+div]:translate-y-[-3px] [&>svg]:absolute [&>svg]:left-4 [&>svg]:top-4',
  {
    variants: {
      variant: {
        info: 'bg-bright-plum-7 text-primary [&>svg]:text-primary',
        warning: 'bg-orange-light-25 text-pastel-orange-dark [&>svg]:text-pastel-orange-dark',
        success: 'bg-green-15 text-green-intense [&>svg]:text-green-intense',
        destructive: 'bg-red-10 text-red-intense [&>svg]:text-red-intense',
      },
    },
    defaultVariants: {
      variant: 'info',
    },
  },
)

/**
 * @dev If possible, use SensayAlert instead of this component.
 */
const BaseAlert = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof alertVariants>
>(({ className, variant, ...props }, ref) => (
  <div ref={ref} role="alert" className={cn(alertVariants({ variant }), className)} {...props} />
))
BaseAlert.displayName = 'BaseAlert'

const AlertTitle = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLHeadingElement>>(
  ({ className, ...props }, ref) => (
    <h5 ref={ref} className={cn(' text-lg font-medium font-sourcesans3 leading-none', className)} {...props} />
  ),
)
AlertTitle.displayName = 'AlertTitle'

const AlertDescription = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={cn('[&_p]:leading-relaxed mt-1 font-sourcesans3 text-burgundy', className)} {...props} />
  ),
)
AlertDescription.displayName = 'AlertDescription'

export { BaseAlert, AlertTitle, AlertDescription }
