'use client'

import { motion, useAnimationControls } from 'framer-motion'
import { useEffect, useState } from 'react'

interface LogoCarouselProps {
  direction?: 'left' | 'right'
  duration?: 'slow' | 'normal' | 'fast'
  className?: string
  children: React.ReactNode
}

export function LogoCarousel({ direction = 'left', duration = 'normal', className = '', children }: LogoCarouselProps) {
  const [isHovering, setIsHovering] = useState(false)
  const controls = useAnimationControls()

  const speedMap = {
    slow: 40,
    normal: 30,
    fast: 20,
  }

  const baseSpeed = speedMap[duration]
  const speed = isHovering ? baseSpeed / 4 : baseSpeed // 3x faster on hover

  useEffect(() => {
    controls.start({
      x: direction === 'left' ? '-66.666%' : '0%',
      transition: {
        duration: speed,
        ease: 'linear',
        repeat: Number.POSITIVE_INFINITY,
        repeatType: 'loop',
        from: direction === 'left' ? '0%' : '-66.666%',
      },
    })
  }, [speed, direction, controls])

  return (
    <div className="relative">
      {/* Hover zones */}
      <div
        className="absolute inset-y-0 left-0 w-32 z-10"
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      />
      <div
        className="absolute inset-y-0 right-0 w-32 z-10"
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      />

      {/* Carousel content */}
      <div className={`w-full overflow-hidden ${className}`}>
        <motion.div animate={controls} className="flex">
          <div className="flex gap-5 md:gap-10 lg:gap-10 items-center py-2 shrink-0">{children}</div>
          <div className="flex gap-5 md:gap-10 lg:gap-10 items-center py-2 shrink-0">{children}</div>
          <div className="flex gap-5 md:gap-10 lg:gap-10 items-center py-2 shrink-0">{children}</div>
        </motion.div>
      </div>
    </div>
  )
}
